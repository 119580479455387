import React from "react"
import fullStar from "./s.svg"
import emptyStar from "./star-empty.svg"
import quarterStar from "./star-quarter.svg"
import halfStar from "./star-half.svg"
import threeQuartersStar from "./star-three-quarters.svg"

import PropTypes from "prop-types"

function Stars(props) {
  let rating = parseFloat(props.rating.toFixed(2))

  const stars = []

  let ones = Math.floor((rating / 1) % 10)
  let remainder = 5 - ones
  let decimalNumber = rating - Math.floor(rating)

  if (ones !== 5) {
    for (let i = 0; i < ones; i++) {
      stars.push(<Star number={1} key={i} />)
    }

    stars.push(<Star number={decimalNumber} key={decimalNumber} />)

    for (let i = 0; i < remainder - 1; i++) {
      stars.push(<Star number={0} key={i} />)
    }
  } else if (ones === 5) {
    for (let i = 0; i < 5; i++) {
      stars.push(<Star number={1} key={i} />)
    }
  }

  return <div className="svg-wrapper google-stars">{stars}</div>
}

function Star(props) {
  let number = props.number

  if (number === 0) {
    return <img role="presentation" src={emptyStar} className="google-star" />
  } else if (number > 0 && number <= 0.25) {
    return <img role="presentation" src={quarterStar} className="google-star" />
  } else if (number > 0.25 && number <= 0.5) {
    return <img role="presentation" src={halfStar} className="google-star" />
  } else if (number > 0.5 && number <= 0.9) {
    return (
      <img
        role="presentation"
        src={threeQuartersStar}
        className="google-star"
      />
    )
  } else if (number > 0.9 && number <= 1) {
    return <img role="presentation" src={fullStar} className="google-star" />
  } else {
    return <img role="presentation" src={emptyStar} className="google-star" />
  }
}

Stars.propTypes = {
  rating: PropTypes.number
}

export default Stars
